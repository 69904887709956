<template>
    <section class="section">
        <div class="container">
            <div class="content is-medium">
                <h1 class="title is-2">Allgemeine Geschäftsbedingungen</h1>
                <AGBContent />
            </div>
        </div>
    </section>
</template>
<script>
    import AGBContent from '@/components/AGBContent'
    export default {
        metaInfo: {
            title: 'Allgemeine Geschäftsbedingungen'
        },
        components: {
            AGBContent
        }
    }
</script>